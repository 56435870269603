var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 p-1" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        staticClass: "duty-inner",
        attrs: { title: _vm.assignedRole.name },
        on: { click: _vm.openDutyModal },
      },
      [
        _c("p", { staticClass: "duty-time text-secondary p-0 m-0 small" }, [
          _vm._v(" " + _vm._s(_vm.dateFormatted)),
        ]),
        _c("p", { staticClass: "duty-time text-secondary p-0 m-0 small" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("time")(_vm.assignedRole.duty.startDateUtc)) +
              " - " +
              _vm._s(_vm._f("time")(_vm.assignedRole.duty.endDateUtc))
          ),
        ]),
        _c("p", { staticClass: "p-0 m-0 small" }, [
          _vm._v(_vm._s(_vm.assignedPersonName)),
        ]),
        _c(
          "p",
          {
            class: [
              "title",
              "font-weight-bold",
              "col-12",
              "p-0",
              "m-0",
              "pr-5",
              _vm.fullyAssigned ? "text-success" : "text-warning",
            ],
          },
          [_vm._v(" " + _vm._s(_vm.assignedRole.name))]
        ),
        _c(
          "span",
          {
            class: [
              "assigments-counter",
              _vm.fullyAssigned ? "bg-success" : "bg-warning",
            ],
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.assigned) +
                " / " +
                _vm._s(_vm.totalAssignments) +
                " "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }