














import { Component, Prop, Vue } from 'vue-property-decorator';
import { DutyRole } from '../../models/duty-role.model';
import { Duty as DutyModel } from '../../models/duty.model';
import DutyAssignmentModal from './DutyAssignmentModal.vue';

@Component
export default class Duty extends Vue {
  @Prop()
  duty!: DutyModel;
  @Prop()
  role!: DutyRole;

  openDutyModal(): void {
    this.$modal.show(DutyAssignmentModal, { duty: this.duty, role: this.role }, { }, { 'before-close': this.onClosed });
  }

  get totalAssignments(): number {
    return this.role.count;
  }

  get assigned(): number {
    return this.duty.assignments.filter(x => x.roleId === this.role.id).length;
  }

  get fullyAssigned(): boolean {
    return this.totalAssignments === this.assigned;
  }

  onClosed(e: { params: { reload: boolean }}): void {
    if (e.params && e.params.reload) {
      this.$emit('closed');
    }
  }
}
