var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "assignments-list-container" }, [
    _c("div", { staticClass: "myduties-container-title mb-2" }, [
      _c("span", { staticClass: "text-primary myduties-container-header" }, [
        _vm._v(_vm._s(_vm.$t("duties.yourDuties"))),
      ]),
      _vm.seasonPoints !== null
        ? _c("span", { staticClass: "myduties-container-points" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("duties.points")) + ":")]),
            _vm._v(
              " " +
                _vm._s(_vm.seasonPoints.memberPoints) +
                "/" +
                _vm._s(_vm.seasonPoints.seasonPoints)
            ),
          ])
        : _vm._e(),
    ]),
    _vm.loading
      ? _c("div", { staticClass: "mt-5" }, [_c("Loader")], 1)
      : _c(
          "div",
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { switch: "", size: "sm" },
                model: {
                  value: _vm.showOld,
                  callback: function ($$v) {
                    _vm.showOld = $$v
                  },
                  expression: "showOld",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("duties.showOld")) + " ")]
            ),
            _vm.assignedDutyRoleItems.length === 0
              ? _c("div", { staticClass: "no-duties-wrapper mt-3" }, [
                  _c("span", { staticClass: "text-secondary" }, [
                    _vm._v(_vm._s(_vm.$t("duties.noPlannedAssignments"))),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "col-12 row p-0 m-0" },
                  [
                    _vm._l(_vm.assignedDutyRoleItems, function (duty, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "col-12 row p-0 m-0 duty-group",
                        },
                        [
                          _c("assignment-item-tile", {
                            attrs: { assignedRole: duty },
                            on: { closed: _vm.onClosed },
                          }),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-center w-100 mt-2",
                      },
                      [
                        _c("b-pagination", {
                          attrs: {
                            size: "sm",
                            "total-rows": _vm.myAssignmentPager.totalEntries,
                            "per-page": _vm.myAssignmentPager.pageSize,
                          },
                          on: { change: _vm.onPaginationChange },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }