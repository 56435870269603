

























import { Member } from '@/modules/login/models/member.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DutyAssignment } from '../../models/duty-assignment.model';
import { DutyRole } from '../../models/duty-role.model';
import { Duty } from '../../models/duty.model';

@Component
export default class DutyAssignmentModal extends Vue {
  @Prop()
  duty!: Duty;
  @Prop()
  role!: DutyRole;

  @Getter
  currentMember!: Member;

  @Action
  private assignToDuty!: (payload: { dutyId: string, roleId: string, occuranceId: string }) => Promise<void>
  @Action
  private unassignFromDuty!:(payload: {dutyId: string, assignmentId: string }) => Promise<void>

  getRoleAssignments(allAssignmets: DutyAssignment[]): DutyAssignment[] {
    return allAssignmets.filter(x => x.roleId == this.role.id);
  }

  canSignin(): boolean {
    return this.duty.canSignin 
      && !this.duty.assignments.some(x => x.personId === this.currentMember.id) 
      && this.role.count > this.duty.assignments.filter(x => x.roleId === this.role.id).length;
  }

  isAssigned() : boolean {
    const assignment = this.getRoleAssignment();
    
    if (!assignment) {
      return false;
    }

    return assignment.personId === this.currentMember.id;
  }

  canSignout(): boolean {
    const assignment = this.getRoleAssignment();
    if (!assignment) {
      return false;
    }

    return this.duty.canSignout 
      && assignment.personId === this.currentMember.id 
      && this.role.allowUnassignment && (dayjs(this.duty.start).subtract(this.role.maxDaysBeforeSignout ? this.role.maxDaysBeforeSignout : 0, 'd') > dayjs())
  }

  close(): void {
    this.$emit('close');
  }

  getRoleAssignment(): DutyAssignment | undefined {
    return this.duty.assignments.find(x => x.personId === this.currentMember.id && x.roleId === this.role.id);
  }

  public async assign(): Promise<void> {
    try {
      const confirmed = await this.$bvModal.msgBoxConfirm(
      `${this.$t('duties.confirmSignin', { role: this.role.roleName })}`,
      { okTitle: `${this.$t('shared.confirm')}`, cancelTitle: `${this.$t('shared.close')}`}
      );
      if (!confirmed) {
        return;
      }
      await this.assignToDuty({ dutyId: this.duty.id, roleId: this.role.id, occuranceId: this.duty.occuranceId });
      ToastHelper.showSuccess(this.$root.$bvToast, this.$t('duties.signinConfirmMessage'));
      this.$emit('close', { reload: true });
    } catch {
      ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMessage'));
    }
  }

  public async unassign(): Promise<void> {
    const assignment = this.getRoleAssignment();
    try {
      const confirmed = await this.$bvModal.msgBoxConfirm(
      `${this.$t('duties.confirmSignout')}`,
      { okTitle: `${this.$t('shared.confirm')}`, cancelTitle: `${this.$t('shared.close')}`}
      );
      if (!confirmed) {
        return;
      }
      await this.unassignFromDuty({ dutyId: this.duty.id, assignmentId: assignment!.id });
      ToastHelper.showSuccess(this.$root.$bvToast, this.$t('duties.signoutConfirmMessage'));
      this.$emit('close', { reload: true });
    } catch {
      ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMessage'));
    }
  }
}
