
























import { Action, Getter } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';

import { Member } from '@/modules/login/models/member.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';

import { MyDutyRoleItemAssignment } from '../../models/my-dutyroleitem-assignment.model';
import { MyDutyRoleItem } from '../../models/my-dutyroleitem.model';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

@Component
export default class RoleItemAssignmentModal extends Vue {

    @Prop()
    assignedRole!: MyDutyRoleItem;
      
    @Action
    private assignToDuty!: (payload: { dutyId: string, roleId: string, occuranceId: string }) => Promise<void>    
    @Action
    private unassignFromDuty!:(payload: {dutyId: string, assignmentId: string }) => Promise<void>

    @Getter
    currentMember!: Member;

    created() {
        dayjs.extend(isSameOrAfter);
    }

    isAssigned() : boolean {
        return this.getRoleAssignment() !== undefined;
    }

    canSignin(): boolean {
        return dayjs(this.assignedRole.duty.startDateUtc).utc().isSameOrAfter(dayjs(), 'day') 
            && !this.isAssigned()
            && this.assignedRole.count > this.assignedRole.assignments.length;
    }

    canSignout() : boolean {
        if (!this.isAssigned()) {
            return false;
        }

        return this.assignedRole.allowUnassignment 
            && dayjs(this.assignedRole.duty.startDateUtc).utc().isSameOrAfter(dayjs(), 'day') 
            && (dayjs(this.assignedRole.duty.startDateUtc).subtract(this.assignedRole.maxDaysBeforeSignout ? this.assignedRole.maxDaysBeforeSignout : 0, 'd') > dayjs())
    }

    getRoleAssignment() : MyDutyRoleItemAssignment | undefined {
        return this.assignedRole.assignments.find(x => x.personId === this.currentMember.id);
    } 
    
    public async assign(): Promise<void> {
        try {
            const confirmed = await this.$bvModal.msgBoxConfirm(
                `${this.$t('duties.confirmSignin', { role: this.assignedRole.name })}`,
                { 
                    okTitle: `${this.$t('shared.confirm')}`, 
                    cancelTitle: `${this.$t('shared.close')}`
                }
            );

            if (!confirmed) {
                return;
            }

            await this.assignToDuty({ 
                dutyId: this.assignedRole.duty.id, 
                roleId: this.assignedRole.roleId, 
                occuranceId: this.assignedRole.occurenceId }
            );

            ToastHelper.showSuccess(this.$root.$bvToast, this.$t('duties.signinConfirmMessage'));

            this.$emit('close', { reload: true });
        } 
        catch {
            ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMessage'));
        }
    }

    public async unassign(): Promise<void> {
        const assignment = this.getRoleAssignment();
    
        try {
            const confirmed = await this.$bvModal.msgBoxConfirm(
                `${this.$t('duties.confirmSignout')}`,
                { 
                    okTitle: `${this.$t('shared.confirm')}`, 
                    cancelTitle: `${this.$t('shared.close')}`
                }
            );

            if (!confirmed) {
                return;
            }

            await this.unassignFromDuty({ 
                dutyId: this.assignedRole.duty.id, 
                assignmentId: assignment!.id 
            });
            
            ToastHelper.showSuccess(this.$root.$bvToast, this.$t('duties.signoutConfirmMessage'));
            
            this.$emit('close', { reload: true });
        } 
        catch {
            ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMessage'));
        }
    }
    
    close(): void {
        this.$emit('close');
    }
}
