var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-6 col-md-4 col-lg-4 p-1" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        staticClass: "duty-inner",
        attrs: { title: _vm.role.roleName },
        on: { click: _vm.openDutyModal },
      },
      [
        _c(
          "p",
          {
            class: [
              "title",
              "font-weight-bold",
              "col-12",
              "p-0",
              "m-0",
              _vm.fullyAssigned ? "text-success" : "text-warning",
            ],
          },
          [_vm._v(" " + _vm._s(_vm.role.roleName))]
        ),
        _c("p", { staticClass: "sub-title col-12 p-0 m-0 pt-2 mb-1" }, [
          _vm._v(" " + _vm._s(_vm.duty.title) + " "),
        ]),
        _c("p", { staticClass: "duty-time text-secondary p-0 m-0 small" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("time")(_vm.duty.start)) +
              " - " +
              _vm._s(_vm._f("time")(_vm.duty.end))
          ),
        ]),
        _vm.role.bonusPoints === 0
          ? _c("p", { staticClass: "duty-time text-secondary p-0 m-0 small" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("duties.points")) +
                  ": " +
                  _vm._s(_vm.role.points)
              ),
            ])
          : _c("p", { staticClass: "duty-time text-secondary p-0 m-0 small" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("duties.points")) +
                  ": " +
                  _vm._s(_vm.role.points) +
                  " (+" +
                  _vm._s(_vm.role.bonusPoints) +
                  " " +
                  _vm._s(_vm.$t("duties.bonus")) +
                  ")"
              ),
            ]),
        _c(
          "span",
          {
            class: [
              "assigments-counter",
              _vm.fullyAssigned ? "bg-success" : "bg-warning",
            ],
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.assigned) +
                " / " +
                _vm._s(_vm.totalAssignments) +
                " "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }