var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 row m-0 p-0 mb-3" },
    [
      _c(
        "h5",
        {
          staticClass: "col-12 text-center text-warning font-weight-bold pt-3",
        },
        [_vm._v(" " + _vm._s(_vm.assignedRole.name))]
      ),
      _c(
        "h5",
        { staticClass: "col-12 text-center text-secondary font-weight-bold" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.assignedRole.duty.title) +
              " - " +
              _vm._s(_vm._f("date")(_vm.assignedRole.duty.startDateUtc)) +
              " "
          ),
        ]
      ),
      _c("h6", { staticClass: "col-12 text-center" }, [
        _vm._v(
          " " +
            _vm._s(_vm._f("time")(_vm.assignedRole.duty.startDateUtc)) +
            " - " +
            _vm._s(_vm._f("time")(_vm.assignedRole.duty.endDateUtc))
        ),
      ]),
      _vm.assignedRole.bonusPoints === 0
        ? _c("p", { staticClass: "col-12 text-center text-secondary" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("duties.points")) +
                ": " +
                _vm._s(_vm.assignedRole.points)
            ),
          ])
        : _c("p", { staticClass: "col-12 text-center text-secondary" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("duties.points")) +
                "(" +
                _vm._s(_vm.$t("duties.bonus")) +
                "): " +
                _vm._s(_vm.assignedRole.points) +
                "(" +
                _vm._s(_vm.assignedRole.bonusPoints) +
                ")"
            ),
          ]),
      _vm._l(_vm.assignedRole.assignments, function (assignment, index) {
        return _c("div", { key: index, staticClass: "col-12 row ml-2" }, [
          _vm._v(" - " + _vm._s(assignment.personName) + " "),
        ])
      }),
      _c(
        "div",
        { staticClass: "buttons col-12 d-flex mt-3 justify-content-between" },
        [
          _c("b-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("shared.close"))),
          ]),
          _vm.isAssigned()
            ? _c(
                "b-button",
                {
                  class: ["ml-2", _vm.canSignout() ? "" : "custom-disabled"],
                  attrs: { id: "unassign-btn", variant: "warning" },
                  on: {
                    click: function ($event) {
                      _vm.canSignout() ? _vm.unassign() : null
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("duties.signout")) + " ")]
              )
            : _vm._e(),
          !_vm.canSignout()
            ? _c(
                "b-tooltip",
                { attrs: { target: "unassign-btn", triggers: "hover" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("duties.unassignDisabledInfo")) + " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.canSignin()
            ? _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { variant: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.assign()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("duties.signin")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }