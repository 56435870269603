










































import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { DutySeasonPoints } from '../../models/duty_season_points.model';
import { MyDutyRoleItem } from '../../models/my-dutyroleitem.model';

import DutyItemRoleAssignment from './DutyItemRoleAssignment.vue';
import RoleItemAssignmentModal from './RoleItemAssignmentModal.vue';

@Component({
    components: {
        "assignment-item-tile": DutyItemRoleAssignment,
        "assignment-modal": RoleItemAssignmentModal
    }
})
export default class DutyItemRoleAssignmentList extends Vue {

    private loading = false;

    @Action
    private loadMyDutyAssignments!: () => Promise<void>;
    @Action
    private loadDutySeasonPoins!: () => Promise<void>;

    @Getter
    assignedDutyRoleItems!: MyDutyRoleItem[];
    @Getter
    myAssignmentPager!: { pageSize: number, currentPage: number };
    @Getter
    showOldAssignments!: boolean;
    @Getter
    seasonPoints!: DutySeasonPoints | null;

    async created() : Promise<void> {
        await this.loadDutySeasonPoins();
        await this.fetchData();
        
    }

    public async fetchData() : Promise<void> {
        try {
            this.loading = true;

            await this.loadMyDutyAssignments();
        }
        catch {
            ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
        }
        finally {
            this.loading = false;
        }
    }

    async onPaginationChange(page: number) : Promise<void> {
        this.currentPage = page;
        await this.fetchData();
    }

    get showOld() : boolean {
        return this.showOldAssignments;
    }
    set showOld(value: boolean) {
        this.$store.commit('setMyAssignmentsShowOld', value)
    }

    get currentPage() : number {
        return this.myAssignmentPager.currentPage;
    }
    set currentPage(value: number) {
        this.$store.commit('setMyAssignmentsPagerPage', value);
    }    

    async onClosed() : Promise<void> {
        await this.loadMyDutyAssignments();
        await this.fetchData();

        this.$emit('closed');
    }

    @Watch('showOld')
    async showOldChanged() : Promise<void> {
        await this.fetchData();
    }
}
