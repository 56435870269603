



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Calendar } from 'v-calendar';

import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Member } from '@/modules/login/models/member.model';

import DutyComponent from './components/Duty.vue';
import DutyAssignmentModal from './components/DutyAssignmentModal.vue';
import DutyItemRoleAssignmentList from './components/DutyItemRoleAssignmentList.vue';

import { DutyListPeriodType } from '../models/duty_list_period_type.model';
import { DutyAssignment } from '../models/duty-assignment.model';
import { DutyRole } from '../models/duty-role.model';
import { Duty as DutyModel } from '../models/duty.model';
import { DutyMemberAcceptance } from '../models/duty_member_acceptance.model';
import dayjs from 'dayjs';

@Component({
  components: {
    'duty': DutyComponent,
    'v-calendar': Calendar,
    'duty-assignment-list': DutyItemRoleAssignmentList
  }
})
export default class Duties extends Vue {
  currentDate = new Date();
  selectedMonth = new Date().getMonth();
  selectedYear = new Date().getFullYear();
  periodType = DutyListPeriodType.Week;
  

  @Action
  private loadDuties!: (payload: { start: Date, end: Date }) => Promise<void>
  @Action
  private loadDutySeasonPoins!: () => Promise<void>
  @Action
  private setLoading!: (isLoading: boolean) => Promise<void>
  @Action
  private getDutyReminderFromToken!: (token: string) => Promise<DutyMemberAcceptance>
  @Getter
  isAppLoading!: boolean;

  @Getter
  personFamilyDutiesFiltered!: DutyModel[];
  @Getter
  otherDutiesFiltered!: DutyModel[];
  @Getter
  currentMember!: Member;
  @Getter
  preselectedDuty!: { duty: DutyModel, role: DutyRole } | null;
  @Getter
  reminderToken!: string | null;

  @Getter
  showDutiesAssigned!: boolean;

  async created(): Promise<void> {
    this.$store.commit('setPreselectedDutyItem', null);

    await this.getPreselectedDuty();
  }

  async getPreselectedDuty() : Promise<void> {

    let reaload = true;

    try {
      await this.setLoading(true);

      if (this.reminderToken) {
        const response = await this.getDutyReminderFromToken(this.reminderToken);

        this.selectedMonth = dayjs(response.startTime).month();
        
        const month = dayjs(response.startTime).month();
        const year= dayjs(response.startTime).year();

        const calendar = this.$refs.calendar;
        reaload = false;

        this.currentDate = new Date(response.startTime);
        this.periodType = DutyListPeriodType.Day;

        await (calendar as any).move({ month: month + 1, year: year });
      }
    }
    catch {
      ToastHelper.showError(this.$bvToast, this.$t('duties.errorGettingFromToken'));
    }
    finally {
      if (reaload) {
        await this.loadForMonth(this.selectedMonth, true);      
      }
    }
  }

  dutiesForDayExists(day: string): boolean {
    const duties = this.otherDutiesFiltered.filter(x => dayjs(dayjs(x.start).local(), 'YYYY-MM-DD').isSame(dayjs(day, 'DD-MM-YYYY'), 'day'));
    if (duties.length === 0) {
      return false;
    }

    return duties.some(x => this.getNotAssignedRoles(x.assignments, x.roles).length > 0);
  }

  getDutiesAndRolesForDay(day: string): { duty: DutyModel, role: DutyRole}[] {
    const duties = this.otherDutiesFiltered.filter(x => dayjs(dayjs(x.start).local(), 'YYYY-MM-DD').isSame(dayjs(day, 'DD-MM-YYYY'), 'day'));

    if (duties.length === 0) {
      return [];
    }

    let result = [] as { duty: DutyModel, role: DutyRole}[];

    for (let i = 0; i < duties.length; i++) {
      var roles = this.getNotAssignedRoles(duties[i].assignments, duties[i].roles);

      const rolesMap = roles.map(x => ({ duty: duties[i], role: x })) as { duty: DutyModel, role: DutyRole}[];
      result = result.concat(rolesMap);
    }

    return result;
  }

  async onDayClick(e: any) {
    this.currentDate = new Date(e.id);

    if (this.periodType === DutyListPeriodType.Day ||
        this.periodType === DutyListPeriodType.Week) {
      await this.fetchDuties();
    }
  }

  async calendarToPage(e: any): Promise<void> {
    const month = e.month;
    const monthParse = month - 1;

    this.selectedMonth = monthParse;
    this.selectedYear = e.year;

    if (this.periodType === DutyListPeriodType.Month) {
      await this.fetchDuties();
    }
  }

  async onClosed(): Promise<void> {
    this.$store.commit('setPreselectedDutyItem', null);
    
    await this.fetchDuties();
    await this.loadDutySeasonPoins();

    (this.$refs.dutyAssignmentList as DutyItemRoleAssignmentList).fetchData();
  }

  async reloadFromList(): Promise<void> {    
    await this.fetchDuties();
  }

  async loadForMonth(monthIndex: number, force: boolean): Promise<void> {
    if (monthIndex === this.selectedMonth && !force) {
      return;
    }

    await this.fetchDuties();
    this.selectedMonth = monthIndex;
  }

  async onDateChanged(): Promise<void> {
    await this.fetchDuties();
  }

  async fetchDuties() : Promise<void> {
    const startEndDates = this.getStartEndDate() as { start: Date, end: Date };

    const start = startEndDates.start;
    const end = startEndDates.end;

    await this.setLoading(true);
    await this.loadDuties({ start, end });
    await this.setLoading(false);

    if (this.preselectedDuty) {
      this.$modal.show(DutyAssignmentModal, { 
        duty: this.preselectedDuty.duty, 
        role: this.preselectedDuty.role }, { 

        }, { 
          'before-close': this.onClosed 
        }
      );
    }
  }

  getStartEndDate() : { start: Date, end: Date } {
    if (this.periodType === DutyListPeriodType.Day) {
        const start = dayjs(this.currentDate).startOf('day').toDate();
        const end = dayjs(this.currentDate).endOf('day').toDate();

        return { start: start, end: end };
    }
    else if (this.periodType === DutyListPeriodType.Week) {
        const start = dayjs(this.currentDate).startOf('week').toDate();
        const end = dayjs(this.currentDate).endOf('week').toDate();

        return { start: start, end: end };
    }

    const month = dayjs().month(this.selectedMonth).year(this.selectedYear);
    const start = month.startOf('month').toDate();
    const end = month.endOf('month').toDate();   
    
    return { start: start, end: end };     
  }
  
  getAssignedRoles(assignments: DutyAssignment[], roles: DutyRole[]): DutyRole[] {
    return roles.filter(x => assignments.some(y => y.personId === this.currentMember.id && y.roleId === x.id));
  }

  getMyAssignedRoles(assignments: DutyAssignment[], roles: DutyRole[]): DutyRole[] {
    return roles.filter(x => assignments.some(y => y.roleId === x.id));
  }

  getNotAssignedRoles(assignments: DutyAssignment[], roles: DutyRole[]): DutyRole[] {
    return roles.filter(x => !assignments.some(y => y.personId === this.currentMember.id && y.roleId === x.id))
  }

  get myDuties() : DutyModel[] {
    if (!this.personFamilyDutiesFiltered) {
      return [];
    }
    return this.personFamilyDutiesFiltered;
  }

  get calendarProps() : [{ key: string, highlight: boolean, dates: Date, order: number }] {
    return [{
      key: 'today',
      highlight: true,
      dates: this.currentDate,
      order: 1
    }];
  }

  get periodTypeOptions() : {text: string, value: DutyListPeriodType }[] {
    return [
      { text: `${this.$t('duties.listPeriodDay')}`, value: DutyListPeriodType.Day },
      { text: `${this.$t('duties.listPeriodWeek')}`, value: DutyListPeriodType.Week },
      { text: `${this.$t('duties.listPeriodMonth')}`, value: DutyListPeriodType.Month }
    ];
  }

  get selectedShowDutiesAssigned() : boolean {
    return this.showDutiesAssigned;
  }
  set selectedShowDutiesAssigned(value: boolean) {
    this.$store.commit('setShowOtherDutiesAssigned', value);
  }

  get datesRange(): string[] {    
    const startEndDates = this.getStartEndDate() as { start: Date, end: Date };

    let startDate = startEndDates.start;
    const endDate = startEndDates.end;

    const result = [];

    while (dayjs(startDate).isBefore(endDate)) {
      result.push(dayjs(startDate).format('DD-MM-YYYY'));
      startDate = dayjs(startDate).add(1, 'd').toDate();
    }

    return result;
  }
  
  @Watch('periodType')
  async periodTypeChanged() : Promise<void> {
    await this.fetchDuties();
  }

}
