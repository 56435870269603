var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 container row" }, [
    _c("h5", { staticClass: "col-12 mt-3" }, [_vm._v("Diensten")]),
    _vm.isAppLoading
      ? _c("div", { staticClass: "col-8 p-0 duties-container" })
      : _c("div", { staticClass: "col-8 p-0 duties-container" }, [
          _c(
            "div",
            { staticClass: "col-12 row" },
            [
              _c(
                "div",
                { staticClass: "col-12 text-left" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "pointer",
                      attrs: { switch: "", size: "sm" },
                      model: {
                        value: _vm.selectedShowDutiesAssigned,
                        callback: function ($$v) {
                          _vm.selectedShowDutiesAssigned = $$v
                        },
                        expression: "selectedShowDutiesAssigned",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("duties.showFullyBooked")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._l(_vm.datesRange, function (day, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-12 row p-0 m-0" },
                  [
                    _vm.dutiesForDayExists(day)
                      ? [
                          _c("h6", { staticClass: "col-12 mt-3" }, [
                            _vm._v(_vm._s(day) + " "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12 row p-0 m-0" },
                            _vm._l(
                              _vm.getDutiesAndRolesForDay(day),
                              function (dutyRole, index) {
                                return _c("duty", {
                                  key: index,
                                  attrs: {
                                    duty: dutyRole.duty,
                                    role: dutyRole.role,
                                  },
                                  on: { closed: _vm.onClosed },
                                })
                              }
                            ),
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]),
    _c(
      "div",
      { staticClass: "col-4" },
      [
        _c(
          "b-form-group",
          [
            _c("template", { slot: "label" }, [
              _c("b", [_vm._v(_vm._s(_vm.$t("duties.showForPeriod")))]),
            ]),
            _c("b-form-radio-group", {
              staticClass: "custom-radio w-100",
              attrs: {
                options: _vm.periodTypeOptions,
                "button-variant": "outline-primary",
                size: "sm",
                buttons: "",
              },
              model: {
                value: _vm.periodType,
                callback: function ($$v) {
                  _vm.periodType = $$v
                },
                expression: "periodType",
              },
            }),
          ],
          2
        ),
        _c("v-calendar", {
          ref: "calendar",
          attrs: {
            attributes: _vm.calendarProps,
            locale: _vm.$i18n.locale,
            "trim-weeks": "",
            "show-weeknumbers": "",
            "is-expanded": "",
          },
          on: {
            "update:to-page": _vm.calendarToPage,
            dayclick: _vm.onDayClick,
          },
        }),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("duty-assignment-list", {
              ref: "dutyAssignmentList",
              on: { closed: _vm.reloadFromList },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }