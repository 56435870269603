












import { Component, Vue, Prop } from 'vue-property-decorator'; 
import { Getter } from 'vuex-class';

import { Member } from '@/modules/login/models/member.model';

import RoleItemAssignmentModal from './RoleItemAssignmentModal.vue';

import { MyDutyRoleItem } from '../../models/my-dutyroleitem.model';
import { AssignmentConnectionType } from '../../models/duty-assignment-connectiontype.model';
import dayjs from 'dayjs';

@Component
export default class MyDuty extends Vue {
    @Prop()
    assignedRole!: MyDutyRoleItem;
    
    @Getter
    currentMember!: Member;

    openDutyModal(): void {
        this.$modal.show(RoleItemAssignmentModal, { assignedRole: this.assignedRole }, { }, { 'before-close': this.onClosed });
    }

    get totalAssignments() : number {
        return this.assignedRole.count;
    }

    get assigned() : number {
        return this.assignedRole.assignments?.length ?? 0;
    }

    get fullyAssigned() : boolean {
        return this.totalAssignments === this.assigned;
    }

    get dateFormatted() : string {
        const dateArr = dayjs(this.assignedRole.duty.startDateUtc).utc().format('dddd DD MMMM YYYY').split(' ');

        const dayOfWeek = this.capitalizeFirstLetter(dateArr[0]);
        const monthName = this.capitalizeFirstLetter(dateArr[2]);

        return `${dayOfWeek} ${dateArr[1]} ${monthName} ${dateArr[3]}`;
    }

    get assignedPersonName() : string {
        const member = this.assignedRole.assignments.find(x => x.personId == this.currentMember.id);

        if (member) {
            return member.personName;
        }

        const familyMember = this.assignedRole.assignments.find(x => x.connectionType == AssignmentConnectionType.family);

        if (familyMember) {
            return familyMember.personName;
        }

        return '-';
    }

    capitalizeFirstLetter(val: string) : string {
        return val.charAt(0).toUpperCase() + val.slice(1);
    }

    onClosed(e: { params: { reload: boolean }}): void {
        if (e.params && e.params.reload) {
            this.$emit('closed');
        }
    }
}
